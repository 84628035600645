<template>
  <b-card
    no-body
  >
    <b-card-body class="m-0 p-0">
      <b-row>
        <b-col>
          <b-card
            class="mb-1"
          >
            <b-row>
              <!-- <b-col lg="3">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="tableData.search"
                    placeholder="Pencarian (tekan enter)"
                    debounce="500"
                    size="sm"
                    autocomplete="off"
                    @keyup.prevent="search()"
                  />
                </b-input-group>
              </b-col> -->
              <!-- <b-col lg="2">
            <b-form-select
              v-model="data.group"
              :options="groupOptions"
              size="sm"
              @change="getGroup()"
            >
              <template #first>
                <b-form-select-option :value="null">
                  -- Pilih Grup --
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-col> -->
              <b-col lg="2">
                <b-form-select
                  v-model="tableData.payload.search_marketplace"
                  :options="parameterOptions"
                  size="sm"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      -- Semua Marketplace --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col lg="2">
                <b-form-select
                  v-model="tableData.payload.status"
                  :options="statusOptions"
                  size="sm"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      -- Semua Status --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col
                lg="3"
              >
                <b-input-group>
                  <flat-pickr
                    v-model="tableData.rangeDate"
                    class="form-control form-control-sm"
                    :config="{ mode: 'range', dateFormat: 'Y-m-d', onClose: (_, dateStr) => { setDate(dateStr)}}"
                    placeholder="pencarian range tanggal"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="secondary"
                      size="sm"
                      @click="clearDate()"
                    >
                      <feather-icon icon="XCircleIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col
                lg="4"
                class="text-right"
              >
                <b-button
                  variant="success"
                  size="sm"
                >
                  <feather-icon icon="DownloadIcon" />
                  Donwload Excel
                </b-button>
              </b-col>
            </b-row>

          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card
            no-body
            style="zoom: 85%"
          >
            <b-card-body class="m-0 p-0">
              <b-table
                id="report-order-table"
                :key="tableData.page"
                :fields="tableFields"
                :items="tableData.data"
                striped
                responsive="lg"
                small
                :busy="isBusy"
              >

                <!-- no data template -->
                <template
                  v-if="tableData.data.length === 0"
                  #top-row
                >
                  <td
                    v-if="tableData.search"

                    colspan="9"
                    class="text-center"
                  >
                    <b-img
                      src="@/assets/images/illustration/data_not_found.png"
                      fluid
                      width="350"
                    />

                    <p class="my-2 text-muted">
                      Kami tidak menemukan apapun, mungkin jodoh anda bukan disini
                    </p>
                  </td>
                  <td
                    v-else
                    colspan="9"
                    class="text-center"
                  >
                    <b-img
                      src="@/assets/images/illustration/task_completed.png"
                      fluid
                      width="250"
                    />
                    <p class="my-2 text-muted">
                      Semua sudah selesai! Tidak ada yang perlu dilakukan
                    </p>
                  </td>
                </template>

                <!-- numbering template -->
                <template #cell(index)="data">
                  {{ (tableData.page === 1 ? data.index + 1 : data.index + (tableData.page * tableData.perPage) + 1 - 10) }}
                </template>

                <!-- total items -->
                <template #cell(total)="data">
                  {{ data.item.order_items.length }}
                </template>

                <!-- action template -->
                <!-- <template #cell(action)="data">
                  <b-button
                    variant="success"
                    size="sm"
                    block

                  >
                    <feather-icon icon="CheckCircleIcon" /> Lihat Detail
                  </b-button>
                </template> -->

                <!-- get order_detail link -->
                <template #cell(order_id)="data">
                  <b-link
                    :to="{ name: 'orderDetails', params: { id: data.item.id } }"
                  >
                    {{ data.item.order_id }}
                  </b-link>
                </template>

                <!--  marketplace icon -->
                <template #cell(shop_id)="data">
                  <b-img
                    v-if="data.item.marketplace === 'tokopedia'"
                    :src="require('@/assets/images/marketplace/tokopedia.png')"
                    class="img-fluid"
                    width="20"
                    height="20"
                  />

                  <b-img
                    v-if="data.item.marketplace === 'shopee'"
                    :src="require('@/assets/images/marketplace/shopee.png')"
                    class="img-fluid"
                    width="20"
                    height="20"
                  />

                  <b-img
                    v-if="data.item.marketplace === 'lazada'"
                    :src="require('@/assets/images/marketplace/lazada.png')"
                    class="img-fluid"
                    width="20"
                    height="20"
                  />

                  <b-img
                    v-if="data.item.marketplace === 'blibli'"
                    :src="require('@/assets/images/marketplace/blibli.png')"
                    class="img-fluid"
                    width="20"
                    height="20"
                  />

                  <b-img
                    v-if="data.item.marketplace === 'jdid'"
                    :src="require('@/assets/images/marketplace/jdid.png')"
                    class="img-fluid"
                    width="20"
                    height="20"
                  />

                  {{ data.item.shop_id }}
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle" />
                    <strong> Memuat Data...</strong>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="tableData.payload.page"
                :total-rows="tableData.totalRows"
                :per-page="tableData.payload.perPage"
                align="right"
                size="md"
                class="mt-2 mr-2"
                aria-controls="report-order-table"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BTabs,
  BRow, BCol,
  BCardBody, BTab,
  BNav,
  BNavItem,
  BImg,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormSelect,
  BFormSelectOption,
  BLink,
  BMedia,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'

import {
  defineComponent, reactive, watch, onMounted, computed, ref, mapGetters,
} from '@vue/composition-api'

// eslint-disable-next-line import/no-cycle
import _ from 'lodash'
import store from '@/store'

// import Swal from 'sweetalert2'

// tab component
// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default defineComponent({
  name: 'Products',
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCardBody,
    BNav,
    BNavItem,
    BImg,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    flatPickr,
    BLink,
    BMedia,
    BPagination,
    BSpinner,
    ToastificationContent,
  },

  setup(props, { root }) {
    // const shipmentModal = defineAsyncComponent(() => import('@/views/orders/components/OrdersShopeeShipment.vue'))
    // const cancelModal = defineAsyncComponent(() => import('@/views/orders/components/OrdersShopeeCancel.vue'))
    // const asyncModal = defineAsyncComponent(() => import('@/views/orders/components/OrdersRefusedModal.vue'))
    const router = root.$router
    const rupiahFormat = value => {
      const rupiah = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      })
      return rupiah.format(value)
    }

    const isBusy = ref(false)

    const tableFields = reactive([
      {
        key: 'index',
        label: 'No',
        class: 'text-center',
      },
      {
        key: 'marketplaceName',
        label: 'Toko',
        class: 'text-center',
      },
      {
        key: 'createdDate',
        label: 'Tgl. Pesan',
        class: 'text-center',
      },
      {
        key: 'orderIdShop',
        label: 'Kode Pesanan',
        class: 'text-center',
      },
      {
        key: 'recipientName',
        label: 'Nama Penerima',
        class: 'text-center',
      },
      {
        key: 'totalPrice',
        label: 'Harga Total',
        class: 'text-center',
      },
      // {
      //     key: 'price_jual',
      //     label: 'Harga Modal',
      // },
      {
        key: 'quantity',
        label: 'Jumlah Barang',
        class: 'text-center',
      },
      {
        key: 'marketplaceType',
        label: 'Marketplace',
      },
      {
        key: 'orderStatusInternal',
        label: 'Status Pesanan',
      },
      {
        key: 'courierName',
        label: 'Kurir',
        class: 'text-center',
      },
      {
        key: 'action',
        label: '',
        class: 'text-center',
      },
    ])

    const tableData = reactive({
      page: 1,
      perPage: 10,
      search: '',
      rangeDate: null,
      totalRows: computed(() => store.state.orders.all.total),
      data: computed(() => {
        isBusy.value = false
        return store.state.orders.all.data.map(item => {
          item.totalPrice = rupiahFormat(item.totalPrice)
          // item.modal_price = rupiahFormat(item.modal_price)
          return item
        })
      }),
      payload: {
        page: 1,
        perPage: 10,
        search_marketplace: '',
        status: '',
        date_start: '',
        date_end: '',
      },
    })

    // const payload = reactive({
    //   page: tableData.page,
    //   perPage: tableData.perPage,
    //   search_marketplace: '',
    //   status: '',
    //   date_start: '',
    //   date_end: '',
    // })

    const getData = async () => {
      if (tableData.payload.status === null) {
        delete tableData.payload.status
      }
      store.dispatch('orders/fetchOrders', tableData.payload)
    }

    const clearDate = () => {
      // tableData.payload.date_start = ''
      // tableData.payload.date_end = ''
      tableData.rangeDate = null
    }

    const search = () => {
      tableData.payload.page = 1
      getData()
    }

    const setDate = selectedDates => {
      const [startDate, endDate] = selectedDates.split(' to ')
      tableData.rangeDate = [startDate, endDate]
      // // console.log(startDate, endDate, '>>date')
      // tableData.payload.date_start = startDate
      // tableData.payload.date_end = endDate
    }

    // watch(() => tableData.payload.date_start, () => {
    //   // payload.rangeDate = tableData.rangeDate
    //   getData()
    // })

    // watch(() => tableData.payload.date_end, () => {
    //   // payload.rangeDate = tableData.rangeDate
    //   getData()
    // })

    watch(() => tableData.rangeDate, () => {
      // eslint-disable-next-line prefer-destructuring
      tableData.payload.date_start = tableData.rangeDate[0]
      // eslint-disable-next-line prefer-destructuring
      tableData.payload.date_end = tableData.rangeDate[1]
    })

    watch(() => tableData.payload.page, () => {
      getData()
    })

    // search by marketplaceName
    const getOrdersByMarketplace = marketplace => computed(() => store.getters.orders.getOrdersByMarketplace(marketplace))

    watch(
      () => tableData.payload.search_marketplace,
      value => {
        // console.log('masuk watch marketplace')
        // console.log(store.getters.orders.getOrdersByMarketplace(value), 'ijnin apsajoahdp iagduistgfyuksdtfyjgsdrbyhfvter')
        getOrdersByMarketplace(value)

        // if (value) {
        // }
      },
    )
    // const getOrdersByStatus = status => computed(() => store.getters.orders.getOrdersByStatus(status))

    watch(
      () => tableData.payload.status,
      value => {
        if (value) {
          getData()
        }
      },
    )

    const parameterOptions = reactive([
      {
        value: 'tokopedia',
        text: 'Tokopedia',
      },
      {
        value: 'shopee',
        text: 'Shopee',
      },
      {
        value: 'lazada',
        text: 'Lazada',
      },
    ])

    const statusOptions = reactive([
      {
        value: 'NEW',
        text: 'Pesanan Baru',
      },
      {
        value: 'ACCEPTED',
        text: 'Diterima',
      },
      {
        value: 'READY_TO_SHIP',
        text: 'Siap Kirim',
      },
      {
        value: 'DELIVERED',
        text: 'Pengiriman',
      },
      {
        value: 'DELIVERED',
        text: 'Terkirim',
      },
      {
        value: 'FINISH',
        text: 'Selesai',
      },
      {
        value: 'CANCEL',
        text: 'Cancel',
      },
    ])

    // watch(
    //   () => payload,
    //   val => {
    //     if (val) {
    //       getData()
    //     }
    //   },
    //   {
    //     deep: true,
    //   },
    // )

    // watch(() => tableData.payload, () => {
    //   getData()
    // })

    watch(
      () => tableData.payload,
      val => {
        if (val) {
          getData()
        }
      },
      {
        deep: true,
      },
    )

    onMounted(() => {
      getData()
    })

    return {
      tableFields,
      tableData,
      isBusy,
      search,
      clearDate,
      setDate,
      parameterOptions,
      statusOptions,
      getData,
      // parameter,
      // payload,
      // printLabel,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
